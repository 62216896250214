export default [
  {
    'id': '1',
    'name': '함평 천지농협 APC',
    'agent': '김도형',
    'description': '양파 중심의 중/소규모 APC',
    'tel': '030-1111-2222',
    'email': 'contact@bgood.co.kr',
    'bankCode': '004',
    'accountNumber': '1111-2222-3333-4444',
    'businessNumber': '020-12-12323',
    'address': {
      postalCode: '12321',
      address1: '전라남도 함평군 천지협',
      address2: '상세주소2'
    },
    'bankAccount': {
      'bankCode': '011',
      'accountNumber': '111111111111',
      'name': '천지농협'
    },
    'products': [
      {
        'id': '1',
        'name': '꼬짜 양파',
        'description': '지름 6cm 이하',
        'status': {
          value: 0,
          key: '판매 중'
        }
      }
    ],
    'createdAt': new Date(),
    'updatedAt': new Date()
  },
  {
    'id': '2',
    'name': '영암 서영암농협 APC',
    'agent': '김도형',
    'description': '고구마 중심의 중/소규모 APC',
    'accountNumber': '1111-2222-3333-4444',
    'tel': '030-1111-2222',
    'email': 'contact@bgood.co.kr',
    'businessNumber': '020-12-12323',
    'address': {
      postalCode: '12321',
      address1: '전라남도 영암군 서영암',
      address2: '상세주소2'
    },
    'bankAccount': {
      'bankCode': '011',
      'accountNumber': '111111111111',
      'name': '서영암농협'
    },
    'products': [
      {
        'id': '2',
        'name': '고구마',
        'description': '꼬불꼬불하거나 무게가 개당 30g 이하'
      }
    ],
    'createdAt': new Date(),
    'updatedAt': new Date()
  }
];

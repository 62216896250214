/* eslint-disable react/no-array-index-key */
import React, {
    lazy,
    Suspense,
    Fragment
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

// const routesConfig = [
//   {
//     exact: true,
//     path: '/',
//     component: () => <Redirect to='/app/management/orders' />
//   },
//   {
//     exact: true,
//     path: '/404',
//     component: lazy(() => import('src/views/pages/Error404View'))
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/login',
//     component: lazy(() => import('src/views/auth/LoginView'))
//   },
//   {
//     exact: true,
//     path: '/login-unprotected',
//     component: lazy(() => import('src/views/auth/LoginView'))
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: '/register',
//     component: lazy(() => import('src/views/auth/RegisterView'))
//   },
//   {
//     exact: true,
//     path: '/register-unprotected',
//     component: lazy(() => import('src/views/auth/RegisterView'))
//   },
//   {
//     path: '/app',
//     guard: AuthGuard,
//     layout: DashboardLayout,
//     routes: [
//       {
//         exact: true,
//         path: '/app',
//         component: () => <Redirect to='/app/management/orders' />
//       },
//       {
//         exact: true,
//         path: '/app/account',
//         component: lazy(() => import('src/views/pages/AccountView'))
//       },
//       {
//         exact: true,
//         path: '/app/reports/dashboard',
//         component: lazy(() => import('src/views/reports/DashboardView'))
//       },
//       {
//         exact: true,
//         path: '/app/reports/dashboard-alternative',
//         component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
//       },
//       {
//         exact: true,
//         path: '/app/reports',
//         component: () => <Redirect to='/app/reports/dashboard' />
//       },
//       {
//         exact: true,
//         path: '/app/management/customers',
//         component: lazy(() => import('src/views/management/CustomerListView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/customers/:customerId',
//         component: lazy(() => import('src/views/management/CustomerDetailsView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/customers/:customerId/edit',
//         component: lazy(() => import('src/views/management/CustomerEditView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/products',
//         component: lazy(() => import('src/views/management/ProductListView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/products/create',
//         component: lazy(() => import('src/views/management/ProductDetailView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/products/:productId',
//         component: lazy(() => import('src/views/management/ProductDetailView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/orders',
//         component: lazy(() => import('src/views/management/OrderListView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/orders/:orderId',
//         component: lazy(() => import('src/views/management/OrderDetailsView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/invoices',
//         component: lazy(() => import('src/views/management/InvoiceListView'))
//       },
//       {
//         exact: true,
//         path: '/app/management/invoices/:invoiceId',
//         component: lazy(() => import('src/views/management/InvoiceDetailsView'))
//       },
//       {
//         exact: true,
//         path: '/app/management',
//         component: () => <Redirect to='/app/management/customers' />
//       },
//       {
//         exact: true,
//         path: '/app/calendar',
//         component: lazy(() => import('src/views/calendar/CalendarView'))
//       },
//       {
//         exact: true,
//         path: '/app/kanban',
//         component: lazy(() => import('src/views/kanban/KanbanView'))
//       },
//       {
//         exact: true,
//         path: [
//           '/app/chat/new',
//           '/app/chat/:threadKey'
//         ],
//         component: lazy(() => import('src/views/chat/ChatView'))
//       },
//       {
//         exact: true,
//         path: '/app/chat',
//         component: () => <Redirect to='/app/chat/new' />
//       },
//       {
//         exact: true,
//         path: [
//           '/app/mail/label/:customLabel/:mailId?',
//           '/app/mail/:systemLabel/:mailId?'
//         ],
//         component: lazy(() => import('src/views/mail/MailView'))
//       },
//       {
//         exact: true,
//         path: '/app/mail',
//         component: () => <Redirect to='/app/mail/all' />
//       },
//       {
//         exact: true,
//         path: '/app/projects/overview',
//         component: lazy(() => import('src/views/projects/OverviewView'))
//       },
//       {
//         exact: true,
//         path: '/app/projects/browse',
//         component: lazy(() => import('src/views/projects/ProjectBrowseView'))
//       },
//       {
//         exact: true,
//         path: '/app/projects/create',
//         component: lazy(() => import('src/views/projects/ProjectCreateView'))
//       },
//       {
//         exact: true,
//         path: '/app/projects/:id',
//         component: lazy(() => import('src/views/projects/ProjectDetailsView'))
//       },
//       {
//         exact: true,
//         path: '/app/projects',
//         component: () => <Redirect to='/app/projects/browse' />
//       },
//       {
//         exact: true,
//         path: '/app/social/feed',
//         component: lazy(() => import('src/views/social/FeedView'))
//       },
//       {
//         exact: true,
//         path: '/app/social/profile',
//         component: lazy(() => import('src/views/social/ProfileView'))
//       },
//       {
//         exact: true,
//         path: '/app/social',
//         component: () => <Redirect to='/app/social/profile' />
//       },
//       {
//         exact: true,
//         path: '/app/extra/charts/apex',
//         component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
//       },
//       {
//         exact: true,
//         path: '/app/extra/forms/formik',
//         component: lazy(() => import('src/views/extra/forms/FormikView'))
//       },
//       {
//         exact: true,
//         path: '/app/extra/forms/redux',
//         component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
//       },
//       {
//         exact: true,
//         path: '/app/extra/editors/draft-js',
//         component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
//       },
//       {
//         exact: true,
//         path: '/app/extra/editors/quill',
//         component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
//       },
//       {
//         component: () => <Redirect to='/404' />
//       }
//     ]
//   },
//   {
//     path: '/docs',
//     layout: DocsLayout,
//     routes: [
//       {
//         exact: true,
//         path: '/docs',
//         component: () => <Redirect to='/docs/welcome' />
//       },
//       {
//         exact: true,
//         path: '/docs/welcome',
//         component: lazy(() => import('src/views/docs/WelcomeView'))
//       },
//       {
//         exact: true,
//         path: '/docs/getting-started',
//         component: lazy(() => import('src/views/docs/GettingStartedView'))
//       },
//       {
//         exact: true,
//         path: '/docs/environment-variables',
//         component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
//       },
//       {
//         exact: true,
//         path: '/docs/deployment',
//         component: lazy(() => import('src/views/docs/DeploymentView'))
//       },
//       {
//         exact: true,
//         path: '/docs/api-calls',
//         component: lazy(() => import('src/views/docs/ApiCallsView'))
//       },
//       {
//         exact: true,
//         path: '/docs/analytics',
//         component: lazy(() => import('src/views/docs/AnalyticsView'))
//       },
//       {
//         exact: true,
//         path: '/docs/authentication',
//         component: lazy(() => import('src/views/docs/AuthenticationView'))
//       },
//       {
//         exact: true,
//         path: '/docs/routing',
//         component: lazy(() => import('src/views/docs/RoutingView'))
//       },
//       {
//         exact: true,
//         path: '/docs/settings',
//         component: lazy(() => import('src/views/docs/SettingsView'))
//       },
//       {
//         exact: true,
//         path: '/docs/state-management',
//         component: lazy(() => import('src/views/docs/StateManagementView'))
//       },
//       {
//         exact: true,
//         path: '/docs/theming',
//         component: lazy(() => import('src/views/docs/ThemingView'))
//       },
//       {
//         exact: true,
//         path: '/docs/support',
//         component: lazy(() => import('src/views/docs/SupportView'))
//       },
//       {
//         exact: true,
//         path: '/docs/changelog',
//         component: lazy(() => import('src/views/docs/ChangelogView'))
//       },
//       {
//         component: () => <Redirect to='/404' />
//       }
//     ]
//   },
//   {
//     path: '*',
//     layout: MainLayout,
//     routes: [
//       {
//         exact: true,
//         path: '/home',
//         component: HomeView
//       },
//       {
//         exact: true,
//         path: '/pricing',
//         component: lazy(() => import('src/views/pages/PricingView'))
//       },
//       {
//         component: () => <Redirect to='/404' />
//       }
//     ]
//   }
// ];


const routeConfig = [
    {
        exact: true,
        path: '/',
        component: () => <Redirect to='/bgood/order'/>
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
    },

    {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        path: '/bgood',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/bgood',
                component: () => <Redirect to={'/bgood/order'}/>
            },
            {
                exact: true,
                path: '/bgood/kb/confirm',
                component: lazy(() => import('src/views/bgood/KBPurchaseConfirmView'))
            },
            {
                exact: true,
                path: '/bgood/order',
                component: lazy(() => import('src/views/bgood/OrderListView'))
            },
            {
                exact: true,
                path: '/bgood/order/create',
                component: lazy(() => import('src/views/bgood/OrderCreateView'))
            },
            {
                exact: true,
                path: '/bgood/order/:orderId',
                component: lazy(() => import('src/views/bgood/OrderDetailsView'))
            },
            {
                exact: true,
                path: '/bgood/product',
                component: lazy(() => import('src/views/bgood/ProductListView'))
            },
            {
                exact: true,
                path: '/bgood/product/create',
                component: lazy(() => import('src/views/bgood/ProductCreateView'))
            },
            {
                exact: true,
                path: '/bgood/product/negotiation',
                component: lazy(() => import('src/views/bgood/ProductNegotiationView'))
            },
            {
              exact: true,
              path: '/bgood/product/:productId',
              component: lazy(() => import('src/views/bgood/ProductDetailView'))
            },
            // {
            //   exact: true,
            //   path: '/bgood/invoices',
            //   component: lazy(() => import('src/views/bgood/InvoiceListView'))
            // },
            // {
            //   exact: true,
            //   path: '/bgood/invoices/:invoiceId',
            //   component: lazy(() => import('src/views/bgood/InvoiceDetailsView'))
            // },
            {
                exact: true,
                path: '/bgood/stock/:stockId',
                component: lazy(() => import('src/views/bgood/ProducerStockDetailView'))
            },
            {
                exact: true,
                path: '/bgood/producer',
                component: lazy(() => import('src/views/bgood/ProducerListView'))
            },
            {
                exact: true,
                path: '/bgood/producer/create',
                component: lazy(() => import('src/views/bgood/ProducerCreateView'))
            },
            {
                exact: true,
                path: '/bgood/producer/:producerId/order',
                component: lazy(() => import('src/views/bgood/ProducerOrderDetailView'))
            },
            {
                exact: true,
                path: '/bgood/producer/:producerId',
                component: lazy(() => import('src/views/bgood/ProducerDetailView'))
            },
            {
                exact: true,
                path: '/bgood/producer/:producerId/stock/:stockId',
                component: lazy(() => import('src/views/bgood/ProducerStockDetailView'))
            },
            {
                exact: true,
                path: '/bgood/customer',
                component: lazy(() => import('src/views/bgood/CustomerListView'))
            },
            {
                exact: true,
                path: '/bgood/customer/:customerId',
                component: lazy(() => import('src/views/bgood/CustomerDetailView'))
            },
            {
                exact: true,
                path: '/bgood/business/order',
                component: lazy(() => import('src/views/bgood/BulkOrderListView'))
            },
            {
                exact: true,
                path: '/bgood/business/order/:id',
                component: lazy(() => import('src/views/bgood/BulkDetailOrderListView'))
            },
            {
                exact: true,
                path: '/bgood/branch',
                component: lazy(() => import('src/views/bgood/BranchListView'))
            },
            {
                exact: true,
                path: '/bgood/branch/:branchId',
                component: lazy(() => import('src/views/bgood/BranchDetailView'))
            },
            {
                component: () => <Redirect to='/404'/>
            }
        ]
    },
    {
        path: '/abid',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/abid/order',
                component: lazy(() => import('src/views/abid/OrderListView'))
            },
            {
                exact: true,
                path: '/abid/order/:orderId',
                component: lazy(() => import('src/views/abid/OrderDetailView'))
            },
            {
                exact: true,
                path: '/abid/product',
                component: lazy(() => import('src/views/abid/ProductListView'))
            },
            {
                exact: true,
                path: '/abid/producer',
                component: lazy(() => import('src/views/abid/ProducerListView'))
            },
            {
                exact: true,
                path: '/abid/producer/create',
                component: lazy(() => import('src/views/abid/ProducerCreateView'))
            },
            {
                exact: true,
                path: '/abid/customer',
                component: lazy(() => import('src/views/abid/CustomerListView'))
            },
            {
                exact: true,
                path: '/abid/customer/:customerId',
                component: lazy(() => import('src/views/abid/CustomerDetailView'))
            },
            {
                exact: true,
                path: '/abid/business/order',
                component: lazy(() => import('src/views/abid/BulkOrderListView'))
            }
        ]
    },
    {
        path: '/account',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/account/business',
                component: lazy(() => import('src/views/account/AccountHistoryListView'))
            }
        ]
    },
    {
        component: () => <Redirect to='/404'/>
    }
];

const renderRoutes = (routes) => (
    routes ? (
        <Suspense fallback={<LoadingScreen/>}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes
                                            ? renderRoutes(route.routes)
                                            : <Component {...props} />}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null);

function Routes() {
    return renderRoutes(routeConfig);
}

export default Routes;

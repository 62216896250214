import axios from 'axios';

const instance = axios.create({
  timeout: 1000,
  headers: { 'Access-Control-Allow-Origin': '*' }
});

export default instance;

export const bgoodApi = axios.create({
  baseURL: 'https://admin-api.bgood.co.kr/v1/bgood',
  // baseURL: 'http://localhost:8000/v1/bgood',
  timeout: 10000,
  headers: { 'Access-Control-Allow-Origin': '*' },
});

export const abiddingApi = axios.create({
  baseURL: 'https://admin-api.bgood.co.kr/v1/abid',
  // baseURL: 'http://localhost:8000/v1/abid',
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' },
});

export const adminApi = axios.create({
  baseURL: 'https://admin-api.bgood.co.kr/v1',
  // baseURL: 'http://localhost:8000/v1',
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
});

export const authApi = axios.create({
  baseURL: 'https://auth.snecompany.com/v1',
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
});

export const utilApi = axios.create({
  baseURL: 'https://util.snecompany.com',
  // baseURL: 'http://localhost:8090',
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
})

export const priceDefineApi = axios.create({
  baseURL: 'https://p7s2koyffi.execute-api.ap-northeast-2.amazonaws.com/product',
  timeout: 30000
})

export const others = axios.create({
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
});

import producers from './producer';

export default [
  {
    'id': '1',
    'name': '꼬짜 양파',
    'description': '지름 6cm 이하',
    'unit': {
      amount: 10,
      unit: 'kg'
    },
    'status': 0,
    'logo': '/nh.png',
    'average': 7800,
    'stock': 12000,
    'producers': [producers[0]],
    'price': 5500,
    'createdAt': new Date(),
    'updatedAt': new Date(),
    'postFee':3500,
  },
  {
    'id': '2',
    'name': '고구마',
    'description': '꼬불꼬불하거나 무게가 개당 30g 이하',
    'status': 1,
    'logo': '/nh.png',
    'average': 24000,
    'unit': {
      amount: 10,
      unit: 'kg'
    },
    'stock': 5000,
    'producers': [producers[1]],
    'price': 18000,
    'createdAt': new Date(),
    'updatedAt': new Date(),
    'postFee':3500,
  }
];

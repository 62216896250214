import userMocks from './user';
import stockMocks from './stock';
import moment from 'moment';

export default [
  {
    id: '1',
    orderId: '6',
    transactionId: '1',
    status: 1,
    customer: userMocks[0],
    stock: { ...stockMocks[0] },
    count: 5,
    taxes: Math.round((stockMocks[0].price * 5) * 0.10 * 0.10),
    transport: 3000 * 5,
    fee: Math.round((stockMocks[0].price * 5) * 0.10),
    subTotal: (stockMocks[0].price * 5),
    total: stockMocks[0].price * 5 + 3000 * 5 + Math.round((stockMocks[0].price * 5) * 0.10) + Math.round((stockMocks[0].price * 5) * 0.10 * 0.10),
    createdAt: moment().toDate().getTime(),
    issuedAt: new Date()
  }
];

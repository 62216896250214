import userMocks from './user';
import productMocks from './product';
import stockMocks from './stock';

const status = [
  '결제 대기 중',
  '상품 준비 중',
  '배송 중',
  '배송 완료',
  '정산 완료',
  '취소',
  '환불 완료'
];


export default [
  {
    id: '1',
    product: { ...productMocks[0] },
    stock: { ...stockMocks[0] },
    amount: 2,
    price: stockMocks[0].price * 2,
    status: 2,
    customer: {
      ...userMocks[0]
    },
    name: userMocks[0].name,
    address: userMocks[0].address,
    email: userMocks[0].email,
    tel: userMocks[0].tel,
    transportId: '1212121212',
    createdAt: new Date('2020-08-23'),
    updatedAt: new Date('2020-08-23')
  }
];

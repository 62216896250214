import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import { others, adminApi, bgoodApi, abiddingApi, authApi, utilApi } from 'src/utils/axios';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    if (!accessToken.startsWith('Bearer')) {
      accessToken = `Bearer ${accessToken}`;
    }
    localStorage.setItem('authorization', accessToken);
    others.defaults.headers.common.Authorization = `${accessToken}`;
    authApi.defaults.headers.common.Authorization = `${accessToken}`;
    adminApi.defaults.headers.common.Authorization = `${accessToken}`;
    bgoodApi.defaults.headers.common.Authorization = `${accessToken}`;
    abiddingApi.defaults.headers.common.Authorization = `${accessToken}`;
    utilApi.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem('authorization');
    delete others.defaults.headers.common.Authorization;
    delete authApi.defaults.headers.common.Authorization;
    delete adminApi.defaults.headers.common.Authorization;
    delete bgoodApi.defaults.headers.common.Authorization;
    delete abiddingApi.defaults.headers.common.Authorization;
    delete utilApi.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user, isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        isAuthenticated: false,
        isInitialised: false,
        user: null,
      };
    }
    case 'REGISTER': {
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {
  },
  register: () => Promise.resolve()
});

const userProperties = [
  'id',
  'name',
  ''
]

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const initialise = async () => {
    try {
      const authorization = window.localStorage.getItem('authorization');
      if (authorization && isValidToken(authorization)) {
        setSession(authorization);

        const response = await authApi.get('/token/verify');
        if (response.data.error) {
          return dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: true,
            user: response.data.result
          }
        });

      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const login = async (registerNumber, password) => {
    const response = await adminApi.post('/login', { registerNumber, password });
    if (response.data.error) {
      return dispatch({
        type: 'LOGIN',
        payload: {
          user: null
        }
      });
    }
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        user,
      }
    });
    await initialise()
  };

  const token = () => {
    const authorization = window.localStorage.getItem('authorization');
    if (authorization && isValidToken(authorization))
      return authorization;
    return null;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await adminApi.post('/auth/register', {
      email,
      name,
      password
    });
    const { authorization, user } = response.data;

    window.localStorage.setItem('authorization', authorization);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    initialise().catch(console.error);
  }, [state?.user?.id]);

  if (!state.isInitialised) {
    return <SplashScreen/>;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Bearer',
        login,
        logout,
        register,
        token
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

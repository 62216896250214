import mock from 'src/utils/mock';
import moment from 'moment';
import userMocks from './mocks/user';
import orderMocks from './mocks/order';
import stockMocks from './mocks/stock';
import productMocks from './mocks/product';
import invoiceMocks from './mocks/invoice';
import producerMocks from './mocks/producer';

import { yyyymmdd } from '../constants';

// users
mock.onGet('https://api.bgood.co.kr/v1/customers/').reply(200, {
  list: userMocks,
  total: userMocks.length
});

mock.onGet('https://api.bgood.co.kr/v1/customers/1').reply(200, {
  item: userMocks[0]
});


// orders
mock.onGet('https://api.bgood.co.kr/v1/orders/').reply(200, {
  list: orderMocks,
  total: orderMocks.length
});
mock.onGet('https://api.bgood.co.kr/v1/orders/1').reply(200, {
  item: orderMocks[0]
});

mock.onPost('https://api.bgood.co.kr/v1/orders/').reply((config) => {
  console.log(config);
  return [200, {}];
});

// products
mock.onGet('https://api.bgood.co.kr/v1/products/').reply(200, {
  list: productMocks,
  total: productMocks.length
});


mock.onGet('https://api.bgood.co.kr/v1/products/1').reply(200, {
  item: productMocks[0]
});

mock.onGet('https://api.bgood.co.kr/v1/products/2').reply(200, {
  item: productMocks[1]
});

// invoice

mock.onGet('https://api.bgood.co.kr/v1/invoices/').reply(200, {
  list: invoiceMocks,
  total: invoiceMocks.length
});

mock.onGet('https://api.bgood.co.kr/v1/invoices/1').reply(200, {
  item: invoiceMocks[0]
});


//producers


// mock.onGet('https://api.bgood.co.kr/v1/producers/').reply(200, {
//   list: producerMocks,
//   total: producerMocks.length
// });
//
//
// mock.onGet('https://api.bgood.co.kr/v1/producers/1').reply(200, {
//   item: producerMocks[0]
// });
//
//
// mock.onGet('https://api.bgood.co.kr/v1/producers/2').reply(200, {
//   item: producerMocks[0]
// });


//stocks


mock.onGet('https://api.bgood.co.kr/v1/stocks/').reply(200, {
  list: stockMocks,
  total: stockMocks.length
});


mock.onGet('https://api.bgood.co.kr/v1/stocks/1').reply(200, {
  item: stockMocks[0]
});



/* eslint-disable import/prefer-default-export */
export * from './cacheKeys';
export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 20;


export const defaultDateFormat = 'YYYY.MM.DD hh:mm';
export const yyyymmdd = 'YYYY.MM.DD';

export const postFee = 3000;
export const transportOptions = [
    { value: 'post', label: '우체국' },
    { value: 'self', label: '자체 배송' },
    { value: 'others', label: '기타' }
];

export const transportCompanies = [
    {
        value: '01',
        label: '우체국택배'
    },
    {
        value: '04',
        label: 'CJ대한통운'
    },
    {
        value: '05',
        label: '한진택배'
    },
    {
        value: '06',
        label: '로젠택배'
    },
    {
        value: '08',
        label: '롯데택배'
    },
    {
        value: '11',
        label: '일양로지스'
    },
    {
        value: '20',
        label: '한덱스'
    },
    {
        value: '22',
        label: '대신택배'
    },
    {
        value: '23',
        label: '경동택배'
    },
    {
        value: '32',
        label: '합동택배'
    }
]

export const historyTypes = [
    { value: 'accIn', label: '입금', color: 'error' },
    { value: 'accOut', label: '출금', color: 'success' }
];

export const orderStatues = [
    {
        color: 'error',
        value: 'payment',
        label: '결제 중'
    },
    {
        color: 'success',
        value: 'ordered',
        label: '주문 (결제 X)'
    },
    {
        color: 'error',
        value: 'prefund',
        label: '선결제 완료'
    },
    {
        color: 'warning',
        value: 'paid',
        label: '결제 확인'
    },
    {
        color: 'warning',
        value: 'report',
        label: '생산자 전달'
    },
    {
        color: 'warning',
        value: 'prepare',
        label: '상품 준비 중'
    },
    {
        color: 'success',
        value: 'delivery',
        label: '배송 중'
    },
    {
        color: 'success',
        value: 'arrival',
        label: '배송 완료'
    },
    {
        color: 'primary',
        value: 'confirmed',
        label: '구매 확정'
    },
    {
        color: 'warning',
        value: 'billing',
        label: '정산 중'
    },
    {
        color: 'primary',
        value: 'complete',
        label: '정산 완료'
    },
    {
        color: 'warning',
        value: 'cancel',
        label: '취소'
    },
    {
        color: 'primary',
        value: 'refund',
        label: '환불 완료'
    }
];

export const registerStatuses = [
    {
        color: 'success',
        value: true,
        label: '승인'
    },
    {
        color: 'error',
        value: false,
        label: '거절'
    }
];


export const invoiceStatuses = [
    {
        color: 'primary',
        value: 0,
        label: '지불 대기'
    },
    {
        color: 'success',
        value: 1,
        label: '지불 완료'
    },
    {
        color: 'error',
        value: 2,
        label: '취소'
    },
    {
        color: 'secondary',
        value: 3,
        label: '환불 완료'
    }
];


export const producerStatuses = [
    {
        color: 'primary',
        value: 0,
        label: '출하'
    },
    {
        color: 'error',
        value: 1,
        label: '일시정지'
    },
    {
        color: 'error',
        value: 2,
        label: '취급중지'
    },
    {
        color: 'warn',
        value: 3,
        label: '영업중지'
    }
];

export const productStatuses = [
    {
        value: true,
        label: '판매중'
    },
    {
        value: false,
        label: '판매 중지'
    }
]

export const productNoticeStatuses = [
    {
        value: false,
        label: '사용'
    },
    {
        value: true,
        label: '미사용'
    }
]

export const bankCodes = {
    '011': '농협',
    '081': '하나은행',
    '004': '국민은행',
    '088': '신한은행',
    '020': '우리은행',
    '003': '기업은행',
    '005': '외환은행',
    '023': 'SC제일은행',
    '045': '새마을금고',
    '007': '수협은행',
    '048': '신협',
    '071': '우체국',
    '037': '전북은행',
    '034': '광주은행',
    '039': '경남은행',
    '032': '부산은행',
    '031': '대구은행',
    '035': '제주은행',
    '002': '산업은행',
    '050': '상호저축은행'
};

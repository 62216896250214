import productMock from '../mocks/product';

export default [
  {
    id: '1',
    product: { ...productMock[0] },
    producer: productMock[0].producers[0],
    stocked: 10000,
    ordered: 2,
    price: 3000,
    createdAt: new Date(),
    stockedAt: new Date(),
    updatedAt: new Date()
  }
];

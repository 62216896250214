/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  // {
  //   subheader: '리포트',
  //   items: [
  //     // {
  //     //   title: '대쉬보드',
  //     //   icon: PieChartIcon,
  //     //   href: '/app/reports/dashboard'
  //     // },
  //     // {
  //     //   title: 'Dashboard Alternative',
  //     //   icon: BarChartIcon,
  //     //   href: '/app/reports/dashboard-alternative'
  //     // }
  //   ]
  // },
  // {
  //   subheader: 'Applications',

  {
    subheader: '서비스 운영',
    items: [
      {
        title: 'Bgood',
        href: '/bgood/order',
        items: [
          {
            title: '주문',
            href: '/bgood/order'
          },
          {
            title: '공동구매 주문',
            href: '/bgood/business/order'
          },
          {
            title: '프렌차이즈 주문',
            href: '/bgood/branch'
          },
          {
            title: '상품',
            href: '/bgood/product'
          },
          {
            title: '생산자',
            href: '/bgood/producer'
          },
          {
            title: '구매자',
            href: '/bgood/customer'
          },
          {
            title: '가격 제안 템플릿',
            href: '/bgood/product/negotiation'
          },
          {
            title: 'KB 정산',
            href: '/bgood/kb/confirm'
          }
        ]
      },
      // {
      //   title: 'a에게 부탁해',
      //   href: '/abid/order',
      //   items: [
      //     {
      //       title: '주문',
      //       href: '/abid/order'
      //     },
      //     {
      //       title: '공동구매 주문',
      //       href: '/abid/business/order'
      //     },
      //     {
      //       title: '상품',
      //       href: '/abid/product'
      //     },
      //     {
      //       title: '생산자',
      //       href: '/abid/producer'
      //     },
      //     {
      //       title: '구매자',
      //       href: '/abid/customer'
      //     }
      //   ]
      // }
    ]
  },

  // {
  //   subheader: '회계 / ERP',
  //   items: [
  //     {
  //       title: '회계',
  //       href: '/account',
  //       items: [
  //         {
  //           title: '회계 내역',
  //           href: '/accounts',
  //         },
  //       ]
  //     }
  //   ]
  // },
  {
    subheader: '관리',
    items: [
      {
        title: '계좌 확인',
        href: '/account',
        items: [
          {
            title: '사업자 계좌',
            href: '/account/business'
          }
        ]
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
                             acc,
                             pathname,
                             item,
                             depth = 0
                           }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ open, onClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  useEffect(() => {
    if (open && onClose) {
      onClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          p={2}
          display="flex"
          justifyContent="center"
        >
          <RouterLink to="/">
            <Logo/>
          </RouterLink>
        </Box>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                // src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.name}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {`${user.tel}`}
            </Typography>
          </Box>
        </Box>
        <Divider/>
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.mobileDrawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
